import { ROLES } from '../utils/constants';
import WebClient from '../utils/web-client';

// WP provides GMT datetimes, but without any timezone identifier for ... reasons?
// Append the Z suffix to indicate GMT (UTC) so passing these datestrings to Date convert from
// GMT to the user's timezone
const markAsGMT = (datetimeString) => (datetimeString ? `${datetimeString}Z` : null);

// Appears that posts are shaped differently for ... reasons not clear to me,
// I assume due to some inconsistency between ACF and WP REST API???
// This function hopefully normalizes away that inconsistency
const resolvePostPolymorphism = (post) => ({
    id: post.ID || post.id,
    postModified: markAsGMT(post.post_modified_gmt || post.modified_gmt),
    postDate: markAsGMT(post.post_date_gmt || post.date_gmt),
    title: post.post_title || post.title?.rendered, // policies actually don't have titles, so allow falling through completely
});

const fromWPUser = (user) => {
    const shape = {
        id: user.id,
        role: user.role,
        name: user.name,
        email: user.email,
    };

    if (user.role === ROLES.CARRIER) {
        return {
            ...shape,
            carrier: {
                id: user.acf.carrier.ID,
                title: user.acf.carrier.post_title,
            },
        };
    }

    if (user.role === ROLES.LAWYER) {
        return {
            ...shape,
            policy: {
                id: user.acf.policy.ID,
                name: user.acf.policy.post_title,
                carrier: {
                    id: user.acf.policy.acf.carrier.ID,
                    title: user.acf.policy.acf.carrier.post_title,
                },
            },
        };
    }

    return shape;
};

const fromWPTerm = (term) => ({
    id: term.term_id || term.id, // term_id if fetched as a field on another entity, id if fetched directly
    value: term.name,
    color: term.acf.color,
});

const fromWPResource = (resource) => ({
    ...resolvePostPolymorphism(resource),
    type: resource.acf.type,
    citation: resource.acf.citation,
    content: resource.acf.content,
    pdfUpload: resource.acf.pdf_upload,
    youtubeUrl: resource.acf.youtube_link,
    instructions: resource.acf.instructions,
    url: resource.acf.url,
    // whack-a-mole trying to resolve empty ACF fields from either undefined props or strings representing empty, so just blanket default to empty array
    categories: (resource.resource_categories || []).map(fromWPTerm),
    carriers: (resource.acf.carriers || []).map(resolvePostPolymorphism), // currently not serving complete carrier data, just as raw post objects (WP format, not ACF format)
    downloadable: resource.acf.downloadable,
});

const fromWPCarrier = (carrier, withFields = true) => {
    const base = resolvePostPolymorphism(carrier);
    if (withFields) {
        return {
            ...base,
            logo: carrier.acf.logo,
            topResources: (carrier.acf.top_resources || []).map(fromWPResource),
            topCles: (carrier.acf.top_cles || []).map(fromWPResource),
            featuredCategories: (carrier.acf.featured_categories || []).map(fromWPTerm),
        };
    }

    return base;
};

const fromWPPolicy = (policy) => ({
    ...resolvePostPolymorphism(policy),
    number: policy.acf.number,
    startDate: policy.acf.start_date,
    endDate: policy.acf.end_date,
    carrier: fromWPCarrier(policy.acf.carrier, false),
});

const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

const resolveWPPagination = (headers) => {
    // https://developer.wordpress.org/rest-api/using-the-rest-api/pagination/
    const totalItems = parseInt(headers['x-wp-total'], 10);
    const totalPages = parseInt(headers['x-wp-totalpages'], 10);

    return {
        totalItems: Number.isNaN(totalItems) ? undefined : totalItems,
        totalPages: Number.isNaN(totalPages) ? undefined : totalPages,
    };
};

const request = async (
    netFn,
    opts = {
        log: false,
        latency: false,
        error: false,
        cancelToken: null,
    },
) => {
    try {
        if (opts.error) {
            // to simulate bug handling (for example, see redux/thunk-crash-reporter)
            if (opts.error === 'system') {
                throw new TypeError('Simulated system error');
            }

            throw new Error('Simulated error');
        }

        if (opts.latency) {
            await sleep(Number.isNaN(parseInt(opts.latency, 10)) ? 2000 : opts.latency);
        }
        const result = await netFn({ cancelToken: opts.cancelToken });

        if (opts.log) {
            // eslint-disable-next-line no-console
            console.log(result);
        }

        return result;
    } catch (err) {
        if (opts.log) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
        throw err;
    }
};

const API = {
    login: async ({ username, password }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post('api/v1/token', { username, password }, { cancelToken }),
            opts,
        );

        const is2FAEnabled = data['2FAStatus'].message === '2fa_set';

        const result = {
            key: data.user_key,
            is2FAEnabled,
        };

        if (!is2FAEnabled) {
            result.qrCode = data['2FAStatus'].url;
            result.secret = data['2FAStatus'].GAuth_Secret;
        }

        return result;
    },
    logout: async (opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post('ll/v1/logout', null, { cancelToken }),
            opts,
        );

        return data;
    },
    verifyUser: async ({ key, code }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post('api/v1/verify-user', { user_key: key, otp: code }, { cancelToken }),
            opts,
        );

        return {
            token: data.jwt_token,
        };
    },
    fetchCurrentUser: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('wp/v2/users/me', { cancelToken }), opts);

        return fromWPUser(data);
    },
    updateCurrentUser: async ({ name, email, password, currentPassword }, opts) => {
        // https://developer.wordpress.org/rest-api/reference/users/#update-a-user-2
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    'wp/v2/users/me',
                    {
                        name,
                        email,
                        password,
                        current_password: currentPassword,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return fromWPUser(data);
    },
    fetchCarrier: async ({ carrierId }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get(`wp/v2/carriers/${carrierId}`, { cancelToken }),
            opts,
        );

        return fromWPCarrier(data);
    },
    fetchCarriers: async (opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get('ll/v1/carriers_public', { cancelToken }),
            opts,
        );

        // Do not map custom fields; this endpoint serves only id and title, serving as a public index of carriers
        // as it's used on not just the admin's carriers view, but also on signup, to see the list of available carriers.
        // Or rather, it used to used on the signup view, but was no longer needed there. Given this bare bones data
        // served the admin view just fine, we kept as-is, but consider it fine to expand the data returned here and
        // protect this endpoint as needed, no longer in-use on any public views
        return data.map((carrier) => fromWPCarrier(carrier, false));
    },
    fetchResource: async ({ id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get(`wp/v2/resources/${id}`, { cancelToken }),
            opts,
        );

        return fromWPResource(data);
    },
    fetchResources: async (
        { carrierId, page, itemsPerPage, search, articleType, type, categories, orderBy, order },
        opts,
    ) => {
        const { data, headers } = await request(
            ({ cancelToken }) =>
                WebClient.get('wp/v2/resources', {
                    params: {
                        article_type: articleType,
                        resource_type: type,
                        carrier_id: carrierId,
                        resource_categories: categories, // auto-created and handled by ACF
                        search,
                        // WP REST API standard pagination params
                        // - https://developer.wordpress.org/rest-api/using-the-rest-api/pagination/
                        // - https://developer.wordpress.org/rest-api/reference/posts/#arguments
                        page,
                        per_page: itemsPerPage,
                        orderby: orderBy,
                        order,
                    },
                    cancelToken,
                }),
            opts,
        );

        return {
            items: data.map(fromWPResource),
            pagination: resolveWPPagination(headers),
        };
    },
    fetchResourceCategories: async (opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get('wp/v2/resource_categories', { cancelToken }),
            opts,
        );

        return data.map(fromWPTerm);
    },
    verifyPolicy: async ({ policyNumber }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    'll/v1/policy_verify',
                    {
                        policy_number: policyNumber,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data.map(fromWPPolicy);
    },
    lawyerSignup: async ({ email, password, name, policyId }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    'll/v1/lawyer_signup',
                    {
                        email,
                        password,
                        name,
                        policy_id: policyId,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    forgotPassword: async ({ email }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post('ll/v1/forgot_password', { email }, { cancelToken }),
            opts,
        );

        return data;
    },
    resetPassword: async ({ login, resetToken, password }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    'll/v1/reset_password',
                    {
                        login,
                        new_password: password,
                        key: resetToken,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    fetchAboutUs: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('ll/v1/about_us', { cancelToken }), opts);

        return {
            ...data,
            team: data.team.map((teamMember) => ({
                name: teamMember.name,
                profileUrl: teamMember.profile_url,
                photo: teamMember.photo,
            })),
        };
    },
    fetchPracticeAreas: async (opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get('ll/v1/practice_areas', { cancelToken }),
            opts,
        );

        return data;
    },
    createRiskConsultationRequest: async (
        { firmName, practiceArea, reason, adverseIndividuals, numAttorneys, numYearsExp, location, phone },
        opts,
    ) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    'll/v1/risk_consultation',
                    {
                        firm_name: firmName,
                        practice_area: practiceArea,
                        reason_for_request: reason,
                        adverse_individuals: adverseIndividuals,
                        num_attorneys: numAttorneys,
                        num_years_exp: numYearsExp,
                        location,
                        phone,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    fetchTermsAndConditions: async (opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get('ll/v1/terms_and_conditions', { cancelToken }),
            opts,
        );

        return data;
    },
    trackEvent: async ({ entityId, type }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    'll/v1/event',
                    {
                        entity_id: entityId,
                        type,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    authCheck: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('ll/v1/auth_check', { cancelToken }), opts);

        return data;
    },
    fetchHomePageContent: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('ll/v1/home_page', { cancelToken }), opts);

        return data;
    },
    fetchBlocks: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('ll/v1/blocks', { cancelToken }), opts);

        return {
            beginConsultationRequest: data.begin_consultation_request,
            requestPresentation: data.request_presentation,
        };
    },
    fetchCLESettings: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('ll/v1/cle_settings', { cancelToken }), opts);

        return {
            creditInfo: {
                title: data.credit_info.title,
                body: data.credit_info.body,
                evaluationFormLink: data.credit_info.evaluation_form_link,
            },
        };
    },
    fetchRiskConsultationSettings: async (opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get('ll/v1/risk_consultation_settings', { cancelToken }),
            opts,
        );

        return {
            stepContent: {
                requestConsultation: data.step_content.request_consultation,
                termsEngagement: data.step_content.terms_engagement,
            },
        };
    },
};

export default API;
